import { default as _91id_93h2RybDLFSxMeta } from "/__w/backoffice/backoffice/pages/accommodations/accommodations/[id].vue?macro=true";
import { default as index5Zb6mZSg5OMeta } from "/__w/backoffice/backoffice/pages/accommodations/accommodations/index.vue?macro=true";
import { default as newIODgfTEv9hMeta } from "/__w/backoffice/backoffice/pages/accommodations/accommodations/new.vue?macro=true";
import { default as indexr1MsLpdIT7Meta } from "/__w/backoffice/backoffice/pages/accommodations/meal-plans/index.vue?macro=true";
import { default as indexbihovznKo9Meta } from "/__w/backoffice/backoffice/pages/accommodations/qualifications/index.vue?macro=true";
import { default as _91id_93ZhEe6P5VTwMeta } from "/__w/backoffice/backoffice/pages/accommodations/room-types/[id].vue?macro=true";
import { default as indexe70uICNhedMeta } from "/__w/backoffice/backoffice/pages/accommodations/room-types/index.vue?macro=true";
import { default as newu8MXjABimrMeta } from "/__w/backoffice/backoffice/pages/accommodations/room-types/new.vue?macro=true";
import { default as indexJYWJR63mc2Meta } from "/__w/backoffice/backoffice/pages/accommodations/types/index.vue?macro=true";
import { default as _91id_9339b3MZzwgGMeta } from "/__w/backoffice/backoffice/pages/activities/activities/[id].vue?macro=true";
import { default as indexN1pZF0RslAMeta } from "/__w/backoffice/backoffice/pages/activities/activities/index.vue?macro=true";
import { default as newWzb9LiKQRAMeta } from "/__w/backoffice/backoffice/pages/activities/activities/new.vue?macro=true";
import { default as forgot_45passwordHPeMFEqHNzMeta } from "/__w/backoffice/backoffice/pages/auth/forgot-password.vue?macro=true";
import { default as reset_45passwordNvIRhoVsXOMeta } from "/__w/backoffice/backoffice/pages/auth/reset-password.vue?macro=true";
import { default as validate_45emailJeAdl0dWDZMeta } from "/__w/backoffice/backoffice/pages/auth/validate-email.vue?macro=true";
import { default as indexQK8MB6JWpzMeta } from "/__w/backoffice/backoffice/pages/bookings/bookings/index.vue?macro=true";
import { default as _91id_931DccNXtDA9Meta } from "/__w/backoffice/backoffice/pages/categories/categories/[id].vue?macro=true";
import { default as indexUWeCk6GuaaMeta } from "/__w/backoffice/backoffice/pages/categories/categories/index.vue?macro=true";
import { default as indextmR6x5AcPnMeta } from "/__w/backoffice/backoffice/pages/categories/tags/index.vue?macro=true";
import { default as _91id_93SoqjEVT6PSMeta } from "/__w/backoffice/backoffice/pages/community/community/hotels/[id].vue?macro=true";
import { default as indexhCXeVulTLXMeta } from "/__w/backoffice/backoffice/pages/community/community/index.vue?macro=true";
import { default as _91id_93SBkmIfzXAwMeta } from "/__w/backoffice/backoffice/pages/community/community/restaurants/[id].vue?macro=true";
import { default as _91id_93Wa4qQG7PX7Meta } from "/__w/backoffice/backoffice/pages/community/community/tour-guides/[id].vue?macro=true";
import { default as index4XE9L2KfeMMeta } from "/__w/backoffice/backoffice/pages/community/suggestions/index.vue?macro=true";
import { default as indexwBruwkxerhMeta } from "/__w/backoffice/backoffice/pages/community/surveys/index.vue?macro=true";
import { default as indexM2MuyGA9FmMeta } from "/__w/backoffice/backoffice/pages/configuration/agents/index.vue?macro=true";
import { default as indexGpDgJWCTUHMeta } from "/__w/backoffice/backoffice/pages/configuration/provinces/index.vue?macro=true";
import { default as indexSVsBbRtBuZMeta } from "/__w/backoffice/backoffice/pages/configuration/public-users/index.vue?macro=true";
import { default as _91id_93T7g9B6YUUjMeta } from "/__w/backoffice/backoffice/pages/configuration/users/[id].vue?macro=true";
import { default as indexHX6oAan7nPMeta } from "/__w/backoffice/backoffice/pages/configuration/users/index.vue?macro=true";
import { default as newSUsljFGbxCMeta } from "/__w/backoffice/backoffice/pages/configuration/users/new.vue?macro=true";
import { default as _91id_93f1IdvOSeoXMeta } from "/__w/backoffice/backoffice/pages/contact-us/contact-us/[id].vue?macro=true";
import { default as indexOo3jFFpeJ0Meta } from "/__w/backoffice/backoffice/pages/contact-us/contact-us/index.vue?macro=true";
import { default as _91id_93tszyKDFbfeMeta } from "/__w/backoffice/backoffice/pages/coupons/coupons/[id].vue?macro=true";
import { default as indexQ18deziVmpMeta } from "/__w/backoffice/backoffice/pages/coupons/coupons/index.vue?macro=true";
import { default as newFTaWaUQv2iMeta } from "/__w/backoffice/backoffice/pages/coupons/coupons/new.vue?macro=true";
import { default as _91id_93o7rddZskE0Meta } from "/__w/backoffice/backoffice/pages/experiences/experiences/[id].vue?macro=true";
import { default as indexODOL496WCWMeta } from "/__w/backoffice/backoffice/pages/experiences/experiences/index.vue?macro=true";
import { default as newnZAPcUsVbiMeta } from "/__w/backoffice/backoffice/pages/experiences/experiences/new.vue?macro=true";
import { default as _91id_93SjWlo9L0WWMeta } from "/__w/backoffice/backoffice/pages/finances/companies/[id].vue?macro=true";
import { default as indexNoWI4SFxwYMeta } from "/__w/backoffice/backoffice/pages/finances/companies/index.vue?macro=true";
import { default as newSruo8IukeYMeta } from "/__w/backoffice/backoffice/pages/finances/companies/new.vue?macro=true";
import { default as _91id_933q22mcxj9eMeta } from "/__w/backoffice/backoffice/pages/finances/credit-cards/[id].vue?macro=true";
import { default as indexy6CCYrLZpxMeta } from "/__w/backoffice/backoffice/pages/finances/credit-cards/index.vue?macro=true";
import { default as newYZBsTeTUBdMeta } from "/__w/backoffice/backoffice/pages/finances/credit-cards/new.vue?macro=true";
import { default as _91id_93YRmcY1cvpgMeta } from "/__w/backoffice/backoffice/pages/finances/customers/[id].vue?macro=true";
import { default as index8JKFvI4zqiMeta } from "/__w/backoffice/backoffice/pages/finances/customers/index.vue?macro=true";
import { default as newum7y19RapOMeta } from "/__w/backoffice/backoffice/pages/finances/customers/new.vue?macro=true";
import { default as _91id_93y92ys2kcyDMeta } from "/__w/backoffice/backoffice/pages/finances/invoices/[id].vue?macro=true";
import { default as indexM4H8go7baGMeta } from "/__w/backoffice/backoffice/pages/finances/invoices/index.vue?macro=true";
import { default as newvFRsWCua74Meta } from "/__w/backoffice/backoffice/pages/finances/invoices/new.vue?macro=true";
import { default as indexQQ2PiToglhMeta } from "/__w/backoffice/backoffice/pages/finances/payment-methods/index.vue?macro=true";
import { default as indexWmlxaLFMGwMeta } from "/__w/backoffice/backoffice/pages/finances/payments/index.vue?macro=true";
import { default as indexMIBivTUHjRMeta } from "/__w/backoffice/backoffice/pages/finances/products/index.vue?macro=true";
import { default as _91id_93Ro71vXdB31Meta } from "/__w/backoffice/backoffice/pages/finances/serials/[id].vue?macro=true";
import { default as indexVQ5QjJZPrYMeta } from "/__w/backoffice/backoffice/pages/finances/serials/index.vue?macro=true";
import { default as newXj1xHzFtdZMeta } from "/__w/backoffice/backoffice/pages/finances/serials/new.vue?macro=true";
import { default as _91id_93PlV2XyqZepMeta } from "/__w/backoffice/backoffice/pages/guides/guides/[id].vue?macro=true";
import { default as indexvLky4djfxeMeta } from "/__w/backoffice/backoffice/pages/guides/guides/index.vue?macro=true";
import { default as newAOM67s53LdMeta } from "/__w/backoffice/backoffice/pages/guides/guides/new.vue?macro=true";
import { default as _91id_93XWHAR9Wrd0Meta } from "/__w/backoffice/backoffice/pages/guides/reservations/[id].vue?macro=true";
import { default as indexacTonbnI0DMeta } from "/__w/backoffice/backoffice/pages/guides/reservations/index.vue?macro=true";
import { default as _91id_93BG1ZlJbEafMeta } from "/__w/backoffice/backoffice/pages/guides/tours/[id].vue?macro=true";
import { default as indexGxhjm4fEK7Meta } from "/__w/backoffice/backoffice/pages/guides/tours/index.vue?macro=true";
import { default as new1YplMjFIBiMeta } from "/__w/backoffice/backoffice/pages/guides/tours/new.vue?macro=true";
import { default as indexzb0TU3uOCCMeta } from "/__w/backoffice/backoffice/pages/human-resources/departments/index.vue?macro=true";
import { default as _91id_93w2ACUmyxjnMeta } from "/__w/backoffice/backoffice/pages/human-resources/employees/[id].vue?macro=true";
import { default as indextJpr02WmpDMeta } from "/__w/backoffice/backoffice/pages/human-resources/employees/index.vue?macro=true";
import { default as new8z77sVMbBSMeta } from "/__w/backoffice/backoffice/pages/human-resources/employees/new.vue?macro=true";
import { default as index8cZMgKec7jMeta } from "/__w/backoffice/backoffice/pages/human-resources/permits/index.vue?macro=true";
import { default as indexG59IZ2hCA7Meta } from "/__w/backoffice/backoffice/pages/human-resources/positions/index.vue?macro=true";
import { default as indexJU5BVCpU5pMeta } from "/__w/backoffice/backoffice/pages/human-resources/signing-reasons/index.vue?macro=true";
import { default as indexjGMHaPqQ1KMeta } from "/__w/backoffice/backoffice/pages/index.vue?macro=true";
import { default as _91id_937Xt7RmU1OqMeta } from "/__w/backoffice/backoffice/pages/itineraries/itineraries/[id].vue?macro=true";
import { default as indexc4CgV8O8YcMeta } from "/__w/backoffice/backoffice/pages/itineraries/itineraries/index.vue?macro=true";
import { default as newiyIiE3yXdjMeta } from "/__w/backoffice/backoffice/pages/itineraries/itineraries/new.vue?macro=true";
import { default as _91id_93LhCU0wiQDSMeta } from "/__w/backoffice/backoffice/pages/locations/locations/[id].vue?macro=true";
import { default as indexG6nsPSuwKHMeta } from "/__w/backoffice/backoffice/pages/locations/locations/index.vue?macro=true";
import { default as newBzg6iNeDgxMeta } from "/__w/backoffice/backoffice/pages/locations/locations/new.vue?macro=true";
import { default as indexdbDod35ru1Meta } from "/__w/backoffice/backoffice/pages/login/index.vue?macro=true";
import { default as _91id_93JTdgkp6piqMeta } from "/__w/backoffice/backoffice/pages/memberships/memberships/[id].vue?macro=true";
import { default as indexP9kl2XfYZMMeta } from "/__w/backoffice/backoffice/pages/memberships/memberships/index.vue?macro=true";
import { default as newyhDSBQwJBaMeta } from "/__w/backoffice/backoffice/pages/memberships/memberships/new.vue?macro=true";
import { default as indexegGawqDXFaMeta } from "/__w/backoffice/backoffice/pages/memberships/services/index.vue?macro=true";
import { default as indexG3yvHqzRJvMeta } from "/__w/backoffice/backoffice/pages/passengers/loyalty-cards/index.vue?macro=true";
import { default as _91id_93USBGIRKRgKMeta } from "/__w/backoffice/backoffice/pages/passengers/passengers/[id].vue?macro=true";
import { default as indexXFSdps9o4TMeta } from "/__w/backoffice/backoffice/pages/passengers/passengers/index.vue?macro=true";
import { default as newzZ9UeKNjDPMeta } from "/__w/backoffice/backoffice/pages/passengers/passengers/new.vue?macro=true";
import { default as indexogwwoqR8K2Meta } from "/__w/backoffice/backoffice/pages/profile/backoffice/index.vue?macro=true";
import { default as indexj70ijXpYnjMeta } from "/__w/backoffice/backoffice/pages/profile/calendar/index.vue?macro=true";
import { default as indexZBO2omGnjPMeta } from "/__w/backoffice/backoffice/pages/profile/guide/index.vue?macro=true";
import { default as indexovYXUC3wSpMeta } from "/__w/backoffice/backoffice/pages/profile/holidays/index.vue?macro=true";
import { default as indexWYmZNxmntpMeta } from "/__w/backoffice/backoffice/pages/profile/signings/index.vue?macro=true";
import { default as _91id_931IaooJeeYmMeta } from "/__w/backoffice/backoffice/pages/restaurants/restaurants/[id].vue?macro=true";
import { default as indexyzhUSsbgICMeta } from "/__w/backoffice/backoffice/pages/restaurants/restaurants/index.vue?macro=true";
import { default as newgvo5EXP5pLMeta } from "/__w/backoffice/backoffice/pages/restaurants/restaurants/new.vue?macro=true";
import { default as _91id_93xSQAz9AoQCMeta } from "/__w/backoffice/backoffice/pages/transports/transports/[id].vue?macro=true";
import { default as indexgZFWr6J5WcMeta } from "/__w/backoffice/backoffice/pages/transports/transports/index.vue?macro=true";
export default [
  {
    name: "accommodations-accommodations-id",
    path: "/accommodations/accommodations/:id()",
    meta: _91id_93h2RybDLFSxMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/accommodations/[id].vue")
  },
  {
    name: "accommodations-accommodations",
    path: "/accommodations/accommodations",
    meta: index5Zb6mZSg5OMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/accommodations/index.vue")
  },
  {
    name: "accommodations-accommodations-new",
    path: "/accommodations/accommodations/new",
    meta: newIODgfTEv9hMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/accommodations/new.vue")
  },
  {
    name: "accommodations-meal-plans",
    path: "/accommodations/meal-plans",
    meta: indexr1MsLpdIT7Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/meal-plans/index.vue")
  },
  {
    name: "accommodations-qualifications",
    path: "/accommodations/qualifications",
    meta: indexbihovznKo9Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/qualifications/index.vue")
  },
  {
    name: "accommodations-room-types-id",
    path: "/accommodations/room-types/:id()",
    meta: _91id_93ZhEe6P5VTwMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/room-types/[id].vue")
  },
  {
    name: "accommodations-room-types",
    path: "/accommodations/room-types",
    meta: indexe70uICNhedMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/room-types/index.vue")
  },
  {
    name: "accommodations-room-types-new",
    path: "/accommodations/room-types/new",
    meta: newu8MXjABimrMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/room-types/new.vue")
  },
  {
    name: "accommodations-types",
    path: "/accommodations/types",
    meta: indexJYWJR63mc2Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/accommodations/types/index.vue")
  },
  {
    name: "activities-activities-id",
    path: "/activities/activities/:id()",
    meta: _91id_9339b3MZzwgGMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/activities/activities/[id].vue")
  },
  {
    name: "activities-activities",
    path: "/activities/activities",
    meta: indexN1pZF0RslAMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/activities/activities/index.vue")
  },
  {
    name: "activities-activities-new",
    path: "/activities/activities/new",
    meta: newWzb9LiKQRAMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/activities/activities/new.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordHPeMFEqHNzMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordNvIRhoVsXOMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/auth/reset-password.vue")
  },
  {
    name: "auth-validate-email",
    path: "/auth/validate-email",
    meta: validate_45emailJeAdl0dWDZMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/auth/validate-email.vue")
  },
  {
    name: "bookings-bookings",
    path: "/bookings/bookings",
    meta: indexQK8MB6JWpzMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/bookings/bookings/index.vue")
  },
  {
    name: "categories-categories-id",
    path: "/categories/categories/:id()",
    meta: _91id_931DccNXtDA9Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/categories/categories/[id].vue")
  },
  {
    name: "categories-categories",
    path: "/categories/categories",
    meta: indexUWeCk6GuaaMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/categories/categories/index.vue")
  },
  {
    name: "categories-tags",
    path: "/categories/tags",
    meta: indextmR6x5AcPnMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/categories/tags/index.vue")
  },
  {
    name: "community-community-hotels-id",
    path: "/community/community/hotels/:id()",
    meta: _91id_93SoqjEVT6PSMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/community/community/hotels/[id].vue")
  },
  {
    name: "community-community",
    path: "/community/community",
    meta: indexhCXeVulTLXMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/community/community/index.vue")
  },
  {
    name: "community-community-restaurants-id",
    path: "/community/community/restaurants/:id()",
    meta: _91id_93SBkmIfzXAwMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/community/community/restaurants/[id].vue")
  },
  {
    name: "community-community-tour-guides-id",
    path: "/community/community/tour-guides/:id()",
    meta: _91id_93Wa4qQG7PX7Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/community/community/tour-guides/[id].vue")
  },
  {
    name: "community-suggestions",
    path: "/community/suggestions",
    meta: index4XE9L2KfeMMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/community/suggestions/index.vue")
  },
  {
    name: "community-surveys",
    path: "/community/surveys",
    meta: indexwBruwkxerhMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/community/surveys/index.vue")
  },
  {
    name: "configuration-agents",
    path: "/configuration/agents",
    meta: indexM2MuyGA9FmMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/configuration/agents/index.vue")
  },
  {
    name: "configuration-provinces",
    path: "/configuration/provinces",
    meta: indexGpDgJWCTUHMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/configuration/provinces/index.vue")
  },
  {
    name: "configuration-public-users",
    path: "/configuration/public-users",
    meta: indexSVsBbRtBuZMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/configuration/public-users/index.vue")
  },
  {
    name: "configuration-users-id",
    path: "/configuration/users/:id()",
    meta: _91id_93T7g9B6YUUjMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/configuration/users/[id].vue")
  },
  {
    name: "configuration-users",
    path: "/configuration/users",
    meta: indexHX6oAan7nPMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/configuration/users/index.vue")
  },
  {
    name: "configuration-users-new",
    path: "/configuration/users/new",
    meta: newSUsljFGbxCMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/configuration/users/new.vue")
  },
  {
    name: "contact-us-contact-us-id",
    path: "/contact-us/contact-us/:id()",
    meta: _91id_93f1IdvOSeoXMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/contact-us/contact-us/[id].vue")
  },
  {
    name: "contact-us-contact-us",
    path: "/contact-us/contact-us",
    meta: indexOo3jFFpeJ0Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/contact-us/contact-us/index.vue")
  },
  {
    name: "coupons-coupons-id",
    path: "/coupons/coupons/:id()",
    meta: _91id_93tszyKDFbfeMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/coupons/coupons/[id].vue")
  },
  {
    name: "coupons-coupons",
    path: "/coupons/coupons",
    meta: indexQ18deziVmpMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/coupons/coupons/index.vue")
  },
  {
    name: "coupons-coupons-new",
    path: "/coupons/coupons/new",
    meta: newFTaWaUQv2iMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/coupons/coupons/new.vue")
  },
  {
    name: "experiences-experiences-id",
    path: "/experiences/experiences/:id()",
    meta: _91id_93o7rddZskE0Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/experiences/experiences/[id].vue")
  },
  {
    name: "experiences-experiences",
    path: "/experiences/experiences",
    meta: indexODOL496WCWMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/experiences/experiences/index.vue")
  },
  {
    name: "experiences-experiences-new",
    path: "/experiences/experiences/new",
    meta: newnZAPcUsVbiMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/experiences/experiences/new.vue")
  },
  {
    name: "finances-companies-id",
    path: "/finances/companies/:id()",
    meta: _91id_93SjWlo9L0WWMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/companies/[id].vue")
  },
  {
    name: "finances-companies",
    path: "/finances/companies",
    meta: indexNoWI4SFxwYMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/companies/index.vue")
  },
  {
    name: "finances-companies-new",
    path: "/finances/companies/new",
    meta: newSruo8IukeYMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/companies/new.vue")
  },
  {
    name: "finances-credit-cards-id",
    path: "/finances/credit-cards/:id()",
    meta: _91id_933q22mcxj9eMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/credit-cards/[id].vue")
  },
  {
    name: "finances-credit-cards",
    path: "/finances/credit-cards",
    meta: indexy6CCYrLZpxMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/credit-cards/index.vue")
  },
  {
    name: "finances-credit-cards-new",
    path: "/finances/credit-cards/new",
    meta: newYZBsTeTUBdMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/credit-cards/new.vue")
  },
  {
    name: "finances-customers-id",
    path: "/finances/customers/:id()",
    meta: _91id_93YRmcY1cvpgMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/customers/[id].vue")
  },
  {
    name: "finances-customers",
    path: "/finances/customers",
    meta: index8JKFvI4zqiMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/customers/index.vue")
  },
  {
    name: "finances-customers-new",
    path: "/finances/customers/new",
    meta: newum7y19RapOMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/customers/new.vue")
  },
  {
    name: "finances-invoices-id",
    path: "/finances/invoices/:id()",
    meta: _91id_93y92ys2kcyDMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/invoices/[id].vue")
  },
  {
    name: "finances-invoices",
    path: "/finances/invoices",
    meta: indexM4H8go7baGMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/invoices/index.vue")
  },
  {
    name: "finances-invoices-new",
    path: "/finances/invoices/new",
    meta: newvFRsWCua74Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/invoices/new.vue")
  },
  {
    name: "finances-payment-methods",
    path: "/finances/payment-methods",
    meta: indexQQ2PiToglhMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/payment-methods/index.vue")
  },
  {
    name: "finances-payments",
    path: "/finances/payments",
    meta: indexWmlxaLFMGwMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/payments/index.vue")
  },
  {
    name: "finances-products",
    path: "/finances/products",
    meta: indexMIBivTUHjRMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/products/index.vue")
  },
  {
    name: "finances-serials-id",
    path: "/finances/serials/:id()",
    meta: _91id_93Ro71vXdB31Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/serials/[id].vue")
  },
  {
    name: "finances-serials",
    path: "/finances/serials",
    meta: indexVQ5QjJZPrYMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/serials/index.vue")
  },
  {
    name: "finances-serials-new",
    path: "/finances/serials/new",
    meta: newXj1xHzFtdZMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/finances/serials/new.vue")
  },
  {
    name: "guides-guides-id",
    path: "/guides/guides/:id()",
    meta: _91id_93PlV2XyqZepMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/guides/guides/[id].vue")
  },
  {
    name: "guides-guides",
    path: "/guides/guides",
    meta: indexvLky4djfxeMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/guides/guides/index.vue")
  },
  {
    name: "guides-guides-new",
    path: "/guides/guides/new",
    meta: newAOM67s53LdMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/guides/guides/new.vue")
  },
  {
    name: "guides-reservations-id",
    path: "/guides/reservations/:id()",
    meta: _91id_93XWHAR9Wrd0Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/guides/reservations/[id].vue")
  },
  {
    name: "guides-reservations",
    path: "/guides/reservations",
    meta: indexacTonbnI0DMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/guides/reservations/index.vue")
  },
  {
    name: "guides-tours-id",
    path: "/guides/tours/:id()",
    meta: _91id_93BG1ZlJbEafMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/guides/tours/[id].vue")
  },
  {
    name: "guides-tours",
    path: "/guides/tours",
    meta: indexGxhjm4fEK7Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/guides/tours/index.vue")
  },
  {
    name: "guides-tours-new",
    path: "/guides/tours/new",
    meta: new1YplMjFIBiMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/guides/tours/new.vue")
  },
  {
    name: "human-resources-departments",
    path: "/human-resources/departments",
    meta: indexzb0TU3uOCCMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/human-resources/departments/index.vue")
  },
  {
    name: "human-resources-employees-id",
    path: "/human-resources/employees/:id()",
    meta: _91id_93w2ACUmyxjnMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/human-resources/employees/[id].vue")
  },
  {
    name: "human-resources-employees",
    path: "/human-resources/employees",
    meta: indextJpr02WmpDMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/human-resources/employees/index.vue")
  },
  {
    name: "human-resources-employees-new",
    path: "/human-resources/employees/new",
    meta: new8z77sVMbBSMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/human-resources/employees/new.vue")
  },
  {
    name: "human-resources-permits",
    path: "/human-resources/permits",
    meta: index8cZMgKec7jMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/human-resources/permits/index.vue")
  },
  {
    name: "human-resources-positions",
    path: "/human-resources/positions",
    meta: indexG59IZ2hCA7Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/human-resources/positions/index.vue")
  },
  {
    name: "human-resources-signing-reasons",
    path: "/human-resources/signing-reasons",
    meta: indexJU5BVCpU5pMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/human-resources/signing-reasons/index.vue")
  },
  {
    name: "IndexPage",
    path: "/",
    meta: indexjGMHaPqQ1KMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/index.vue")
  },
  {
    name: "itineraries-itineraries-id",
    path: "/itineraries/itineraries/:id()",
    meta: _91id_937Xt7RmU1OqMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/itineraries/itineraries/[id].vue")
  },
  {
    name: "itineraries-itineraries",
    path: "/itineraries/itineraries",
    meta: indexc4CgV8O8YcMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/itineraries/itineraries/index.vue")
  },
  {
    name: "itineraries-itineraries-new",
    path: "/itineraries/itineraries/new",
    meta: newiyIiE3yXdjMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/itineraries/itineraries/new.vue")
  },
  {
    name: "locations-locations-id",
    path: "/locations/locations/:id()",
    meta: _91id_93LhCU0wiQDSMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/locations/locations/[id].vue")
  },
  {
    name: "locations-locations",
    path: "/locations/locations",
    meta: indexG6nsPSuwKHMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/locations/locations/index.vue")
  },
  {
    name: "locations-locations-new",
    path: "/locations/locations/new",
    meta: newBzg6iNeDgxMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/locations/locations/new.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexdbDod35ru1Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/login/index.vue")
  },
  {
    name: "memberships-memberships-id",
    path: "/memberships/memberships/:id()",
    meta: _91id_93JTdgkp6piqMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/memberships/memberships/[id].vue")
  },
  {
    name: "memberships-memberships",
    path: "/memberships/memberships",
    meta: indexP9kl2XfYZMMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/memberships/memberships/index.vue")
  },
  {
    name: "memberships-memberships-new",
    path: "/memberships/memberships/new",
    meta: newyhDSBQwJBaMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/memberships/memberships/new.vue")
  },
  {
    name: "memberships-services",
    path: "/memberships/services",
    meta: indexegGawqDXFaMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/memberships/services/index.vue")
  },
  {
    name: "passengers-loyalty-cards",
    path: "/passengers/loyalty-cards",
    meta: indexG3yvHqzRJvMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/passengers/loyalty-cards/index.vue")
  },
  {
    name: "passengers-passengers-id",
    path: "/passengers/passengers/:id()",
    meta: _91id_93USBGIRKRgKMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/passengers/passengers/[id].vue")
  },
  {
    name: "passengers-passengers",
    path: "/passengers/passengers",
    meta: indexXFSdps9o4TMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/passengers/passengers/index.vue")
  },
  {
    name: "passengers-passengers-new",
    path: "/passengers/passengers/new",
    meta: newzZ9UeKNjDPMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/passengers/passengers/new.vue")
  },
  {
    name: "profile-backoffice",
    path: "/profile/backoffice",
    meta: indexogwwoqR8K2Meta || {},
    component: () => import("/__w/backoffice/backoffice/pages/profile/backoffice/index.vue")
  },
  {
    name: "profile-calendar",
    path: "/profile/calendar",
    meta: indexj70ijXpYnjMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/profile/calendar/index.vue")
  },
  {
    name: "profile-guide",
    path: "/profile/guide",
    meta: indexZBO2omGnjPMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/profile/guide/index.vue")
  },
  {
    name: "profile-holidays",
    path: "/profile/holidays",
    meta: indexovYXUC3wSpMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/profile/holidays/index.vue")
  },
  {
    name: "profile-signings",
    path: "/profile/signings",
    meta: indexWYmZNxmntpMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/profile/signings/index.vue")
  },
  {
    name: "restaurants-restaurants-id",
    path: "/restaurants/restaurants/:id()",
    meta: _91id_931IaooJeeYmMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/restaurants/restaurants/[id].vue")
  },
  {
    name: "restaurants-restaurants",
    path: "/restaurants/restaurants",
    meta: indexyzhUSsbgICMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/restaurants/restaurants/index.vue")
  },
  {
    name: "restaurants-restaurants-new",
    path: "/restaurants/restaurants/new",
    meta: newgvo5EXP5pLMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/restaurants/restaurants/new.vue")
  },
  {
    name: "transports-transports-id",
    path: "/transports/transports/:id()",
    meta: _91id_93xSQAz9AoQCMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/transports/transports/[id].vue")
  },
  {
    name: "transports-transports",
    path: "/transports/transports",
    meta: indexgZFWr6J5WcMeta || {},
    component: () => import("/__w/backoffice/backoffice/pages/transports/transports/index.vue")
  }
]